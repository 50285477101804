<template>
    <div class="signin-body">
        <div class="form-signin">
        <!--
            <img class="mb-4" src="../../assets/logos/bootstrap/bootstrap-solid.svg" alt="" width="72" height="72">
        -->
            <img class="mb-4" src="@/assets/logo.png" alt="" width="160">
            <h1 class="h3 mb-3 font-weight-normal">Reset your password</h1>

            <!-- Display server error messages, from server validation -->
            <!-- Diplay alert message received from backend -->
            <AltertMessage
                v-bind:data="formUtilsComp.state.form.response"
            ></AltertMessage>

            <!-- Display server exceptions, from database -->
            <ExceptionMessage
                v-bind:exception="formUtilsComp.state.form.exception"
            ></ExceptionMessage>

            <div class="form-group mb-3">
                <input
                    v-model="authComp.state.form.body.email"
                    @keyup.enter="requestResetPassword()"
                    type="email"
                    id="inputEmail"
                    class="form-control"
                    v-bind:class="formUtilsComp.isEmailValid(authComp.state.form.body.email) ? 'is-valid' : 'is-invalid'"
                    placeholder="Email address"
                    required autofocus
                >
                <div v-if="!formUtilsComp.isEmailValid(authComp.state.form.body.email)" class="text-info text-sm">Please enter a valid email address</div>
                <!-- Diplay alert message received from backend -->
                <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.email"></ValidationErrors>
            </div>

            <hr>

            <button
                v-if="formUtilsComp.isEmailValid(authComp.state.form.body.email)"
                @click="requestResetPassword"
                class="btn btn-lg btn-primary btn-block"
            >Reset password</button>

<!--
            <button
                @click="requestResetPassword"
                class="btn btn-lg btn-primary btn-block"
            >Force Reset password</button>
-->

            <hr>

            <div class="mt-3">
                <router-link  :to="{ name : 'auth-login' }">Back to login</router-link>
            </div>

            <hr>

            <p class="mt-5 mb-3 text-muted">&copy; 2017-2020</p>
        </div>

    </div>

</template>


<script>
// @ is an alias to /src

import axios       from 'axios';

// import composables files
import useAuthComp from '@/composables/useAuthComp.js';
import useFormUtilsComp from '@/composables/useFormUtilsComp.js';

// import informations and errors messages components
import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

export default {

    name: 'Forgot-Password',

    components : {
        AltertMessage,
        ValidationErrors,
        ExceptionMessage,
    },

    setup() {
        const authComp = useAuthComp();
        const formUtilsComp = useFormUtilsComp()

        async function requestResetPassword() {

            formUtilsComp.useResetErrors()

            await axios({
                method: 'post',
                url: 'auth/forgot-password',
                data: {
                    email: authComp.state.form.body.email,
                },
            })
            .then((res) => {
                formUtilsComp.useMessages(res.data, 'alert-success')
            })
            .catch((err) => {
                formUtilsComp.useSetErrors(err.response.data)
            })
        }

        // data returned to the template view
        return {
            authComp,
            formUtilsComp,
            requestResetPassword,
        }
    }
}
</script>

<style>
    @import '../../assets/css/signin.css';
</style>
